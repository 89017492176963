body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 150px; /* Taille ajustée du logo */
}

.glow-card {
  box-shadow: 0 0 15px 5px rgba(255, 255, 255, 0.7),
    0 0 30px 10px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  transition: box-shadow 0.3s ease-in-out;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
